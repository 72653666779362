import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  Divider,
  Container,
  Card,
  Button,
  Input,
  Radio,
  Checkbox,
  Form,
  Grid,
  Label,
  Header,
  Message,
  Image,
  GridColumn,
  Icon,
  Segment
} from 'semantic-ui-react'

const styles = {
  multiplers: {
    padding: 10
  },
  mc_image: {
    marginLeft: 10
  },
  containerStyle: {
    width: '450px',
    marginTop: 40
  },
  table: {
    margin: 20
  },
  title: {
    margin: 20,
    align: 'center'
  },
  productTagSpacing: {
    margin: 10
  }
}

interface propInterface {
  onCalculation: () => void
}

const CalculatorTS: React.FC<propInterface> = props => {
  const [productName, setProductName] = useState('')
  const [productURL, setProductURL] = useState('')
  const [price, setPrice] = useState(0)
  const [originalPrice, setOriginalPrice] = useState(0)
  const [promoPrice, setPromoPrice] = useState(0)
  const [promoEndDate, setPromoEndDate] = useState('')
  const [linkType, setLinkType] = useState('')
  const [onSale, setOnSale] = useState(false)
  const [pointsValue, setPointsValue] = useState(0)
  const [pointsMultiplier, setPointsMultiplier] = useState(1)
  const [mastercardUsed, setMastercardUsed] = useState(false)
  const [loading, setLoading] = useState(false)
  //TODO: change to when form validaion is implemened
  const [formValid, setFormValid] = useState(true)
  const [formValidation, setFormValidation] = useState({})

  const [saved, setSaved] = useState(false)

  const scrapeURLForProductDetails = async url => {
    const data = {
      product: {
        url: url
      }
    }

    setLoading(true)

    const response = await fetch('/api/product_scrape', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-ww w-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })

    const json = await response.json()
    console.log(json)

    setProductName(json.name)
    setOriginalPrice(json.original_price)
    setPromoPrice(json.promo_price)
    setPromoEndDate(json.promo_end)
    setOnSale(json.is_on_sale)
    setLinkType(json.link_type)

    if (json.is_on_sale === true) {
      setPrice(json.promo_price)
    } else {
      setPrice(json.original_price)
    }

    setLoading(false)

    props.onCalculation()
  }

  const handlePriceChange = e => {
    let newPrice = e.target.value

    setSaved(false)
    setPrice(newPrice)
  }

  const handleNameChange = e => {
    let newName = e.target.value

    setSaved(false)
    setProductName(newName)
  }

  const handleURLChange = e => {
    let newUrl = e.target.value

    // scrapeURLForProductDetails(newUrl)

    setSaved(false)
    setProductURL(newUrl)
  }

  const handlePointsMultiplierChange = e => {
    setSaved(false)
    setPointsMultiplier(+e.target.value)
  }

  const handleMastercardUsedChange = e => {
    setSaved(false)
    setMastercardUsed(e.target.checked)
  }

  useEffect(() => {
    const updatePointsValue = () => {
      var pointsPerDollar = 0.004 * pointsMultiplier

      if (mastercardUsed) {
        pointsPerDollar = pointsPerDollar + 0.04
      }

      setPointsValue(price * pointsPerDollar)
    }

    updatePointsValue()
  })

  const saveCalculation = async () => {
    setLoading(true)

    if (isNaN(Number(price))) {
      setFormValidation({ price: false })
    } else {
      const data = {
        reward_calculation: {
          price,
          organization_credit_card_used: mastercardUsed,
          product_name: productName,
          product_url: productURL,
          rewards_value: pointsValue,
          final_price: price - pointsValue,
          points_multiplier: pointsMultiplier
        }
      }

      const response = await fetch('/api/reward_calculations', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })

      setFormValidation({ price: true })

      setSaved(true)
      props.onCalculation()
    }

    setLoading(false)
  }

  return (
    <Container style={styles.containerStyle}>
      <Card centered={true} fluid={true}>
        <h2 style={styles.title}>Triangle Rewards Calculator</h2>
        <Card.Content>
          <Form error>
            {/* <Form.Field>
              <label>
                Product URL{' '}
                <Label size="mini" color={'green'}>
                  New!
                </Label>
              </label>
              <p>Canadian Tire URLs will automatically get the name and price!</p>
              <Input
                size="large"
                placeholder="URL"
                id="url"
                type="text"
                value={productURL}
                fluid={true}
                onChange={e => {
                  handleURLChange(e)
                }}
              >
                <input />
                <Button loading={loading} positive type="submit" onClick={() => scrapeURLForProductDetails(productURL)}>
                  Search
                </Button>
              </Input>
            </Form.Field>
            {linkType == 'canadian_type' && (
              <div style={styles.productTagSpacing}>
                <Label>Link Type: Canadian Tire</Label>
              </div>
            )}
            {linkType == null && (
              <div style={styles.productTagSpacing}>
                <Message size="small">
                  <Message.Header>This link type it not yet supported for automatic search</Message.Header>
                  <Message.List>
                    <Message.Item>Please manually fill out the name and price.</Message.Item>
                  </Message.List>
                </Message>
              </div>
            )}
            {onSale == true && (
              <>
                <div style={styles.productTagSpacing}>
                  <Label>Original price: ${originalPrice}</Label>
                </div>
                <div style={styles.productTagSpacing}>
                  <Label>Promo price: ${promoPrice}</Label>
                </div>
                <div style={styles.productTagSpacing}>
                  <Label>Promo end date: {promoEndDate}</Label>
                </div>
              </>
            )}

            <Divider horizontal>
              <Header as="h4">Or</Header>
            </Divider> */}
            <Form.Field>
              <label>Product Name</label>
              <Input
                size="large"
                placeholder="Name"
                id="name"
                type="text"
                value={productName}
                fluid={false}
                onChange={e => {
                  handleNameChange(e)
                }}
              />
            </Form.Field>
            <Form.Field error={formValidation['price'] == false}>
              <label>Product Price</label>
              <Input
                size="large"
                placeholder="Price"
                id="price"
                type="text"
                value={price}
                fluid={false}
                onChange={e => {
                  handlePriceChange(e)
                }}
              />
            </Form.Field>
            {formValidation['price'] == false && (
              <Message
                error
                header="Incorrect money format"
                content="Please confirm the money format is correct. Only numbers and 1 decimal is permitted"
              />
            )}
            <Form.Field>
              <label>Multipliers</label>

              <Grid padded columns="equal">
                <Grid.Row>
                  <Grid.Column>
                    <Radio
                      label="Regular"
                      id="pointsMultiplierOption1"
                      name="pointsMultiplier"
                      value="1"
                      checked={pointsMultiplier === 1}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Radio
                      label="5x"
                      id="pointsMultiplierOption5"
                      name="pointsMultiplier"
                      value="5"
                      checked={pointsMultiplier === 5}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Radio
                      label="10"
                      id="pointsMultiplierOption10"
                      name="pointsMultiplier"
                      value="10"
                      checked={pointsMultiplier === 10}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Radio
                      label="20x"
                      id="pointsMultiplierOption20"
                      name="pointsMultiplier"
                      value="20"
                      checked={pointsMultiplier === 20}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Radio
                      label="25x"
                      id="pointsMultiplierOption25"
                      name="pointsMultiplier"
                      value="25"
                      checked={pointsMultiplier === 25}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Radio
                      label="30x"
                      id="pointsMultiplierOption30"
                      name="pointsMultiplier"
                      value="30"
                      checked={pointsMultiplier === 30}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Radio
                      label="40x"
                      id="pointsMultiplierOption40"
                      name="pointsMultiplier"
                      value="40"
                      checked={pointsMultiplier === 40}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Radio
                      label="50x"
                      id="pointsMultiplierOption50"
                      name="pointsMultiplier"
                      value="50"
                      checked={pointsMultiplier === 50}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Radio
                      label="60x"
                      id="pointsMultiplierOption60"
                      name="pointsMultiplier"
                      value="60"
                      checked={pointsMultiplier === 60}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Radio
                      label="80x"
                      id="pointsMultiplierOption80"
                      name="pointsMultiplier"
                      value="80"
                      checked={pointsMultiplier === 80}
                      onChange={e => {
                        handlePointsMultiplierChange(e)
                      }}
                      style={styles.multiplers}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <Form.Field>
              <label>Triangle Mastercard</label>

              <Checkbox
                id="ctMastercardUsed"
                label="Triangle Mastercard used?"
                onChange={e => {
                  handleMastercardUsedChange(e)
                }}
              />
              <Image style={styles.mc_image} src="images/triangle_mc_stack.png" size="mini" wrapped></Image>
            </Form.Field>
            <Button loading={loading} positive disabled={!formValid} onClick={saveCalculation}>
              Calculate Reward Value
            </Button>
            {saved && (
              <>
                <Divider />
                <Form.Field>
                  <Header as="h3">Price: ${price}</Header>
                  <Header as="h3" color={'green'}>
                    Rewards Value: ${pointsValue.toFixed(2)}
                  </Header>

                  <Header as="h2">Final Price: ${(price - pointsValue).toFixed(2)}</Header>
                </Form.Field>
              </>
            )}
          </Form>
        </Card.Content>
      </Card>
    </Container>
  )
}

export default CalculatorTS
