import * as React from 'react'

import Calculator from './Calculator'
import CalculationsTable from './CalculationsTable'
import { Container, Card, Icon } from 'semantic-ui-react'

const style = {
  footer: {},
  footerContainer: {
    marginTop: 20,
    paddingTop: 20,
    paddingBottom: 10,
    minHeight: '20vh',
    backgroundColor: 'lightgrey'
  }
}

const App = () => {
  const [forceUpdate, setForceUpdate] = React.useState(1)

  const handleCalculationSave = () => {
    setForceUpdate(forceUpdate + 1)
  }

  return (
    <>
      <Container centered>
        <Calculator onCalculation={() => handleCalculationSave()} />
        <CalculationsTable forceUpdate={forceUpdate} />
      </Container>

      <div style={style.footerContainer}>
        <Container style={style.footer}>
          <p>
            <strong>
              *Rewards Value is an estimate. There is no guarantee that the points collected will reflect the stated
              amount.
            </strong>
          </p>
          <p>
            <strong>
              **This website is in no way affiliated with Canadian Tire or the Triangle Rewards Program. It is simply a
              way to calculate potential points earned.
            </strong>
          </p>

          <Container textAlign="center">
            <p>
              If you have any questions or feature requests please{' '}
              <a href="mailto:rewardscalculator@gmail.com">contact me!</a>
            </p>
            <p>
              Made with <Icon disabled color="red" name="heart" /> in Canada
            </p>

            <a href="https://ko-fi.com/F1F326SFQ" target="_blank">
              <img
                height="36"
                style={{ border: '0px', height: '36px' }}
                src="https://cdn.ko-fi.com/cdn/kofi2.png?v=2"
                alt="Buy Me a Coffee at ko-fi.com"
              />
            </a>

            {/* <a href="https://www.buymeacoffee.com/coffeecoding" target="_blank">
              <img
                src="https://cdn.buymeacoffee.com/buttons/v2/arial-blue.png"
                alt="Buy Me A Coffee"
                style={{ height: '60px', width: '217px' }}
              />
            </a> */}
          </Container>
        </Container>
      </div>
    </>
  )
}

export default App
