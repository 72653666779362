import * as React from 'react'
import { useState, useEffect } from 'react'
import { Icon, Label, Menu, Table, Header } from 'semantic-ui-react'
import { createMedia } from '@artsy/fresnel'
import { Segment } from 'semantic-ui-react'

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992
    // largeScreen: 1200,
    // widescreen: 1920
  }
})

const mediaStyles = AppMedia.createMediaStyle()
const { Media, MediaContextProvider } = AppMedia

interface Calculations {
  productName: string
  productUrl: string
  organiationCreditCardUsed: boolean
  originalProductPrice: number
  rewardValue: number
  finalPrice: number
}

interface propsInterface {
  forceUpdate: number
}

const CalculationsTable: React.FC<propsInterface> = props => {
  const [tableData, setTableData] = useState<any | null>([])
  const [forceUpdate, setForceUpdate] = useState(props.forceUpdate)

  useEffect(() => {
    const fetchCalculations = () => {
      fetch('/api/reward_calculations').then(res =>
        res.json().then(data => {
          setTableData(data)
        })
      )
    }
    fetchCalculations()
  }, [props.forceUpdate])

  const mediaStyles = AppMedia.createMediaStyle()
  const { Media, MediaContextProvider } = AppMedia

  return (
    <>
      <style>{mediaStyles}</style>
      <MediaContextProvider>
        {/* <Segment as={Media} at="mobile">
          Mobile
        </Segment>
        <Segment as={Media} at="tablet">
          Tablet
        </Segment>
        <Segment as={Media} at="computer">
          Computer
        </Segment>
        <Segment as={Media} at="largeScreen">
          Large Screen
        </Segment>
        <Segment as={Media} at="widescreen">
          Widescreen
        </Segment> */}

        <Segment as={Media} at="mobile">
          <Table celled>
            <Table.Body>
              {tableData.map(calculation => (
                <Table.Row>
                  <Table.Cell>
                    <strong>Product:</strong> <a href={calculation.product_url}>{calculation.product_name}</a>
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <strong>Points Multiplier:</strong> {calculation.points_multiplier}
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <strong>Triangle Mastercard Used:</strong>{' '}
                    {calculation.organization_credit_card_used == true ? 'Yes' : 'No'}
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <strong>Original Price:</strong> ${Number(calculation.original_product_price)?.toFixed(2)}
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <strong>Reward Value: </strong> ${Number(calculation.rewards_value)?.toFixed(2)}
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <strong>Final Price: </strong> ${Number(calculation.final_price)?.toFixed(2)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>

        <Segment as={Media} greaterThanOrEqual="tablet">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product Name</Table.HeaderCell>
                <Table.HeaderCell>Points Multiplier</Table.HeaderCell>
                <Table.HeaderCell>Triangle Mastercard Used?</Table.HeaderCell>
                <Table.HeaderCell>Original Price</Table.HeaderCell>
                <Table.HeaderCell>Reward Value</Table.HeaderCell>
                <Table.HeaderCell>Final Price</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tableData.map(calculation => (
                <Table.Row>
                  <Table.Cell>
                    <a href={calculation.product_url}>{calculation.product_name}</a>
                  </Table.Cell>
                  <Table.Cell textAlign="center">{calculation.points_multiplier}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {calculation.organization_credit_card_used == true ? 'Yes' : 'No'}
                  </Table.Cell>
                  <Table.Cell textAlign="right">${Number(calculation.original_product_price)?.toFixed(2)}</Table.Cell>
                  <Table.Cell textAlign="right">${Number(calculation.rewards_value)?.toFixed(2)}</Table.Cell>
                  <Table.Cell textAlign="right">${Number(calculation.final_price)?.toFixed(2)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </MediaContextProvider>
    </>
  )
}

export default CalculationsTable
